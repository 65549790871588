import React, { FunctionComponent } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogSlug: (selection: any) => void;
};

export const LiveBlogSlugSelect: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogSlug }) => {
	const onSlugChange = (input: string) => {
		setLiveBlogSlug(input);
	};

	return (
		<Row className='mb-3'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SLUG}>
					<strong>{t('live_blog_slug')}</strong>
				</Label>
				<span className='text-danger'>*</span>
				<Input
					id={'slug'}
					value={liveBlog.slug ? liveBlog.slug : ''}
					className='form-control mb-2 border-dark'
					type='text'
					placeholder={t('slug_placeholder')}
					onChange={(input: any) => onSlugChange(input)}
				/>
				<ErrorAsync t={t} errorType='slug_id' />
			</Col>
		</Row>
	);
};
